import * as React from 'react';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import ContactUs from '@lce/intl-ui/src/PageComponents/ContactUs';
import useLocation from '../api/ContactPage/useLocation';
import useSiteConfig from '../api/Config/useSiteConfig';
import Layout from '../ui/ConnectedLayout';

const ContactUsPage: React.FC<IDefaultPageProps> = props => {
  const { locale } = props.pathContext;
  const locationData = useLocation(locale);
  const { siteConfig: { googleMapKey }, direction } = useSiteConfig(locale);

  return (
    <Layout {...props} >
      <ContactUs {...locationData} direction={direction} googleMapKey={googleMapKey} legalText={locationData.legalContent.legalText} />
    </Layout>
  );
};

export default ContactUsPage;
